import RyanTransLogo from "../../images/RyanTransLogo.svg";
import "../FeedbackContainer/feedbackContainer.scss";
const FeedbackContainer = () => {
  return (
    <div id="feedbackContainer">
      <div id="feedbackLogo">
        <img src={RyanTransLogo} alt="Ryan Trans Logo" />
      </div>
      <div>
        <p id="feedbackHeaderText">Feedback Portal</p>
      </div>
    </div>
  );
};

export default FeedbackContainer;
