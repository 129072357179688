import "../PositiveReviewPage/PositiveReviewPage.scss";
import SuccessMessage from "../SuccessMessage/SuccessMessage";
const PositiveReviewPage = () => {
  return (
    <>
      <div className="contentContainer">
        <SuccessMessage />
        <div>
          <p id="reviewMessage">
            If you have time, feel free to leave Ryan<br></br> Transportation a
            review on Google or DAT.
          </p>
        </div>
        <div id="buttonContainer">
          <a
            href="https://www.google.com/search?q=ryan+transportation&rlz=1C1GCEB_enUS1004US1005&oq=ryan+transportation&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg9MgYIAhBFGDwyBggDEEUYPNIBCDMxMTVqMGoxqAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x87c0eb54519b9d39:0xd93224ddec46676e,3"
            target="_blank"
            rel="noreferrer"
          >
            <button>Google Review</button>
          </a>
          <a
            href="https://registry.dat.com/custdir/pleasereview.jsp?coid=197104"
            target="_blank"
            rel="noreferrer"
          >
            <button>DAT Review</button>
          </a>
        </div>
      </div>
    </>
  );
};

export default PositiveReviewPage;
