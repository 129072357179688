import React from "react";
import yellowStar from "../../images/yellowStar.svg";
import greyStar from "../../images/greyStar.svg";

//Returns back an image (yellowStar.svg)
//Takes two props: 1. OnclickHandler. 2. A function that determines what svg image to use.
//Feedback form container imports this component and loops over how many times you want to render it.

type RateStarProps = {
  onClick: Function;
  isStarSelected?: boolean;
  index?: number;
};

const RatingStar: React.FC<RateStarProps> = ({
  onClick,
  isStarSelected,
  index,
}) => {
  let star;

  if (isStarSelected) {
    star = yellowStar;
  } else {
    star = greyStar;
  }

  return (
    <img
      src={star}
      onClick={() => onClick(index)}
      className="star"
      alt={`Star rating ${index}`}
    />
  );
};

export default RatingStar;
