import { Routes, Route, Navigate } from "react-router";
import FeedbackContainer from "./components/FeedbackContainer/FeedbackContainer";
import FeedbackFormContainer from "./components/FeedbackFormContainer/FeedbackFormContainer";
import "./App.scss";
import UnsubscribeConfirmation from "./components/UnsubscribeConfirmation/UnsubscribeConfirmation";
import { useSearchParams } from "react-router-dom";

const RedirectToRating = () => {
  const [searchParams] = useSearchParams();
  const orderNum = searchParams.get("orderNum");
  return (
    <Navigate
      // Only adding the search param if it exists already so as not to expose what we are expecting
      to={`/rating${orderNum ? `?orderNum=${orderNum}` : ""}`}
      replace={true}
    />
  );
};

function App() {
  return (
    <>
      <div id="gridContainer">
        <FeedbackContainer />
        <Routes>
          <Route path="/rating" element={<FeedbackFormContainer />} />
          <Route path="/unsubscribe" element={<UnsubscribeConfirmation />} />
          <Route path="*" element={<RedirectToRating />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
