import "../NeutralReviewPage/NeutralReviewPage.scss";
import SuccessMessage from "../SuccessMessage/SuccessMessage";
const NeutralReviewPage = () => {
  return (
    <div className="contentContainer">
      <SuccessMessage />
    </div>
  );
};

export default NeutralReviewPage;
