import "../FeedbackFooter/feedbackFooter.scss";

const FeedbackFooter = () => {
  return (
    <div id="feedbackFooter">
      <p>
        © Ryan Transportation Service, Inc. All rights reserved
        <span className="lineSpacing">|</span>
        <a href="https://www.ryantrans.com/page/terms-use">Terms of use</a>
        <span className="lineSpacing">|</span>
        <a href="https://www.ryantrans.com/page/privacy-policy">
          Privacy Policy
        </a>
        <span className="lineSpacing">|</span>
        <a href="https://www.ryantrans.com/page/customer-portal-terms-of-use">
          Terms and Conditions
        </a>
      </p>
    </div>
  );
};

export default FeedbackFooter;
