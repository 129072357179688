import mailPicture from "../../images/mailPicture.svg";
import "../NegativeReviewPage/NegativeReviewPage.scss";
import { useState } from "react";
import SuccessMessage from "../SuccessMessage/SuccessMessage";
import { sendCommentPost } from "../../api/carrierFeedbackApi";
import { Snackbar } from "shamrock-clover-ui";

const NegativeReviewPage = () => {
  const [characterCount, setCharacterCount] = useState<number>(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [textAreaMessage, setTextAreaMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  if (showSuccessMessage) {
    return (
      <div className="contentContainer">
        <SuccessMessage />
      </div>
    );
  }

  const onButtonClick = async () => {
    try {
      setShowErrorMessage(false);
      setIsLoading(true);
      await sendCommentPost(textAreaMessage);
      setShowSuccessMessage(true);
    } catch (error) {
      setShowErrorMessage(true);
      setIsLoading(false);
    }
  };

  return (
    <div className="contentContainer">
      <img src={mailPicture} alt="Decorative letter" id="mailPicture" />
      <div>
        <p id="negativeMessage">
          We are sorry your experience<br></br> was unsatisfactory.
        </p>
      </div>
      <div id="emailLink">
        <p>Want to tell us what went wrong?</p>
        <p>
          Comment here or email<br></br>
          <a href="mailto:resolution@ryantrans.com">resolution@ryantrans.com</a>
        </p>
      </div>
      <div id="textAreaBoxContainer">
        <textarea
          onChange={(e) => {
            setCharacterCount(e.target.value.length);
            setTextAreaMessage(e.target.value);
          }}
          maxLength={300}
          id="textAreaBox"
          className={characterCount === 300 ? "characterLimit" : ""}
          placeholder="Type your comments here"
        ></textarea>
      </div>
      <div id="textCount">
        {characterCount === 300 ? (
          <p
            id="characterMax"
            className={characterCount === 300 ? "limitReached" : ""}
          >
            Maximum character limit met
          </p>
        ) : (
          <p>Optional</p>
        )}

        <p
          id="maxCount"
          className={characterCount === 300 ? "maxCharacters" : ""}
        >
          {characterCount}/300
        </p>
      </div>

      <div id="doneButtonContainer">
        <button
          id="doneButton"
          disabled={characterCount === 0 || isLoading}
          onClick={onButtonClick}
        >
          {isLoading ? "Submitting..." : "Done"}
        </button>
      </div>
      <Snackbar
        duration={10 * 1000} // 10 seconds
        message="Something went wrong. Try again."
        open={showErrorMessage}
        variant="error"
      />
    </div>
  );
};

export default NegativeReviewPage;
