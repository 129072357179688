import LottieView from "lottie-react";
import successCheckmark from "../../animations/successfinal1.json";
const SuccessMessage = () => {
  return (
    <>
      <LottieView
        style={{ width: 247, height: 232 }}
        resource={require("../../animations/successfinal1.json")}
        autoPlay
        loop={false}
        animationData={successCheckmark}
      />
      <div id="messageContainer">
        <p>Thanks for your feedback!</p>
      </div>
    </>
  );
};

export default SuccessMessage;
