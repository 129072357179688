import feedbackImage from "../../images/feedbackImage.svg";
import RatingStar from "../RatingStar/RatingStar";
import { useState } from "react";
import { sendRatingPost } from "../../api/carrierFeedbackApi";
import "../InitialFeedbackRating/InitialFeedbackRating.scss";
import { Snackbar } from "shamrock-clover-ui";

type InitialFeedbackRatingProps = {
  onRatingSubmit: Function;
};

const InitialFeedbackRatingPage: React.FC<InitialFeedbackRatingProps> = ({
  onRatingSubmit,
}) => {
  const MAX_RATING = 5;
  const [rating, setRating] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const onButtonClick = async () => {
    try {
      setShowErrorMessage(false);
      setIsLoading(true);
      await sendRatingPost(rating);
      onRatingSubmit(rating);
    } catch (error) {
      setShowErrorMessage(true);
      setIsLoading(false);
    }
  };

  const onStarClick = (rating: number) => {
    setRating(rating);
  };

  const renderStars = () => {
    let stars = [];

    for (let i = 1; i <= MAX_RATING; i++) {
      stars.push(
        <RatingStar
          onClick={onStarClick}
          index={i}
          isStarSelected={i <= rating}
          key={i}
        />
      );
    }
    return stars;
  };

  return (
    <div className="contentContainer">
      <p id="headerText">Your opinion matters to us.</p>
      <img src={feedbackImage} alt="Decorative phone" id="feedbackPhone" />
      <div id="starRating">
        <div id="subHeader">
          <p id="subHeaderText">Rate your overall experience.</p>
        </div>
        <div id="starContainer">{renderStars()}</div>
        <div id="satisfaction">
          <p>Dissatisfied</p>
          <p>Very Satisfied</p>
        </div>
      </div>
      <div id="submitButtonContainer">
        <button
          id="submitButton"
          disabled={rating === 0 || isLoading}
          onClick={onButtonClick}
        >
          {isLoading ? "Submitting..." : "Submit"}
        </button>
      </div>
      <Snackbar
        duration={10 * 1000} // 10 seconds
        message="Something went wrong. Try again."
        open={showErrorMessage}
        variant="error"
      />
    </div>
  );
};

export default InitialFeedbackRatingPage;
