import axios from "axios";

const BASE_URL = process.env.REACT_APP_CARRIER_FEEDBACK_SERVICE_URL;

const orderNum = () => {
  const queryParams = new URLSearchParams(window.location.search.toLowerCase());
  return queryParams.get("ordernum") as string;
};

export const sendRatingPost = async (rating: number) => {
  try {
    await axios.post(`${BASE_URL}/carrier-feedback/rating`, {
      rating: rating,
      ordersId: orderNum(),
    });
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};

export const sendCommentPost = async (review: string) => {
  try {
    await axios.post(`${BASE_URL}/carrier-feedback/comment`, {
      review: review,
      ordersId: orderNum(),
    });
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};

export const sendUnsubscribePost = async () => {
  try {
    await axios.post(`${BASE_URL}/carrier-feedback/unsubscribe`, {
      ordersId: orderNum(),
    });
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};
