import "../UnsubscribeConfirmation/UnsubscribeConfirmation.scss";
import FeedbackFooter from "../FeedbackFooter/FeedbackFooter";
import { sendUnsubscribePost } from "../../api/carrierFeedbackApi";
import LottieView from "lottie-react";
import successCheckmark from "../../animations/successfinal1.json";
import { useState } from "react";
import { Snackbar } from "shamrock-clover-ui";

const UnsubscribeConfirmation = () => {
  const [isUnsubscribed, setIsUnsubscribed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const onClick = async () => {
    try {
      setShowErrorMessage(false);
      setIsLoading(true);
      await sendUnsubscribePost();
      setIsUnsubscribed(true);
    } catch {
      setShowErrorMessage(true);
      setIsLoading(false);
    }
  };

  return (
    <div className="unsubscribeContainer">
      <div className="contentContainer">
        {isUnsubscribed ? (
          <>
            <LottieView
              style={{ width: 247, height: 232, paddingBottom: 24 }}
              resource={require("../../animations/successfinal1.json")}
              autoPlay
              loop={false}
              animationData={successCheckmark}
            />
            <div id="confirmationContainer">
              <p id="primaryText">You have been unsubscribed.</p>
            </div>
          </>
        ) : (
          <div className="textContainer">
            <p id="primaryText">
              Please confirm that you want to opt-out of Ryan Transportation
              feedback emails.
            </p>
            <button id="unsubscribeButton" onClick={onClick}>
              {isLoading ? "Unsubscribing..." : "Unsubscribe"}
            </button>
          </div>
        )}
        <Snackbar
          duration={10 * 1000} // 10 seconds
          message="Something went wrong. Try again."
          open={showErrorMessage}
          variant="error"
        />
      </div>
      <FeedbackFooter />
    </div>
  );
};

export default UnsubscribeConfirmation;
