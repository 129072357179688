import NegativeReviewPage from "../NegativeReviewPage/NegativeReviewPage";
import NeutralReviewPage from "../NeutralReviewPage/NeutralReviewPage";
import PositiveReviewPage from "../PositiveReviewPage/PostiveReviewPage";
import "../FeedbackFormContainer/feedbackFormContainer.scss";
import "../FeedbackFooter/FeedbackFooter";
import InitialFeedbackRatingPage from "../InitialFeedbackRating/InitialFeedbackRatingPage";
import FeedbackFooter from "../FeedbackFooter/FeedbackFooter";
import { useState } from "react";

export const FeedbackFormContainer = () => {
  const [rating, setRating] = useState<number>(0);

  const onRatingSubmit = async (rating: number) => {
    setRating(rating);
  };

  return (
    <div id="feedbackFormContainer">
      {rating === 0 && (
        <InitialFeedbackRatingPage onRatingSubmit={onRatingSubmit} />
      )}
      {(rating === 1 || rating === 2) && <NegativeReviewPage />}
      {rating === 3 && <NeutralReviewPage />}
      {rating >= 4 && <PositiveReviewPage />}
      <FeedbackFooter></FeedbackFooter>
    </div>
  );
};

export default FeedbackFormContainer;
